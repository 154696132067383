<template>
  <div class="personalInfo">
    <div class="navBar">
      <div @click="$router.go(-1)" class="back">
        <svg-icon class="leftBtn" iconClass="back"></svg-icon>
        <span>返回</span>
      </div>
      <div class="title">编辑资料</div>
      <div class="rightBtn"></div>
    </div>

    <div class="main">
      <div @click="changeAvatar">
        <ImgDecypt class="userAvatar" round :src="userInfo.portrait" :key="userInfo.portrait" />
      </div>
      <div class="changeBtn" @click="changeAvatar">
        <svg-icon class="changeBtnIcon" iconClass="changeBtn1"></svg-icon>
        <span>切换头像</span>
      </div>

      <div class="cellType">基础信息</div>
      <div class="cellItem">
        <div class="title">昵称</div>
        <div class="content" @click="jumpNickName">
          <span>{{ userInfo.name }}</span>
          <svg-icon class="personalArrow" iconClass="right_arrow"></svg-icon>
        </div>
      </div>
      <div class="cellItem" @click.stop>
        <div class="title">ID</div>
        <div class="content">
          <span class="pink">{{ userInfo.uid }}</span>
          <!-- <svg-icon class="personalArrow" iconClass="right_arrow"></svg-icon> -->
        </div>
      </div>
      <div class="cellItem" @click="jumpIntroduction">
        <div class="title">个人简介</div>
        <div class="content">
          <span>{{ userInfo.summary ? userInfo.summary : '未设置' }}</span>
          <svg-icon class="personalArrow" iconClass="right_arrow"></svg-icon>
        </div>
      </div>
      <div class="cellItem" @click="changeSex">
        <div class="title">性别</div>
        <div class="content">
          <span>{{ gender ? gender : '未设置' }}</span>
          <svg-icon class="personalArrow" iconClass="right_arrow"></svg-icon>
        </div>
      </div>
      <div class="cellItem" @click="jumoCity">
        <div class="title">所在地</div>
        <div class="content">
          <span>{{ userInfo.region ? userInfo.region : '未设置' }}</span>
          <svg-icon class="personalArrow" iconClass="right_arrow"></svg-icon>
        </div>
      </div>

      <div class="cellType mt37">账号</div>
      <div class="cellItem" @click="jumpPhone">
        <div class="title">手机号</div>
        <div class="content">
          <span>{{ userInfo.mobile }}</span>
          <svg-icon class="personalArrow" iconClass="right_arrow"></svg-icon>
        </div>
      </div>
      <router-link tag="div" to="/actCredentials" class="cellItem">
        <div class="title">账号凭证</div>
        <svg-icon class="personalArrow" iconClass="right_arrow"></svg-icon>
      </router-link>
      <router-link tag="div" to="/actRecovery" class="cellItem">
        <div class="title">找回账号</div>
        <svg-icon class="personalArrow" iconClass="right_arrow"></svg-icon>
      </router-link>

      <div class="cellType mt37">其他</div>
      <router-link tag="div" to="/kefu" class="cellItem">
        <div class="title">联系客服</div>
        <svg-icon class="personalArrow" iconClass="right_arrow"></svg-icon>
      </router-link>
      <router-link tag="div" to="/privacyPolicy" class="cellItem">
        <div class="title">隐私政策</div>
        <svg-icon class="personalArrow" iconClass="right_arrow"></svg-icon>
      </router-link>
      <router-link tag="div" to="/termsOfService" class="cellItem">
        <div class="title">服务条款</div>
        <svg-icon class="personalArrow" iconClass="right_arrow"></svg-icon>
      </router-link>
      <router-link tag="div" to="/about" class="cellItem mb40">
        <div class="title">关于APP</div>
        <svg-icon class="personalArrow" iconClass="right_arrow"></svg-icon>
      </router-link>
    </div>
    <van-popup v-model="isChangeSex" position="bottom" class="sexSelect">
      <div class="title">选择性别</div>
      <van-radio-group v-model="currentSex" direction="horizontal" class="sexBox">
        <van-radio name="man" checked-color="rgb(255,90,144)" label-position="left">
          <template #icon="props">
            <div class="maleBox">
              <span :class="props.checked ? 'pink' : ''">男</span>
              <img class="img-icon" :src="props.checked ? activeIcon : inactiveIcon" />
            </div>
          </template>
        </van-radio>
        <van-radio name="woman" checked-color="rgb(255,90,144)" label-position="left">
          <template #icon="props">
            <div class="femaleBox">
              <span :class="props.checked ? 'pink' : ''">女</span>
              <img class="img-icon" :src="props.checked ? activeIcon : inactiveIcon" />
            </div>
          </template>
        </van-radio>
      </van-radio-group>
      <div class="confirm" @click="selectSex">确认</div>
    </van-popup>
  </div>
</template>

<script>
import ImgDecypt from '@/components/ImgDecypt';
import { modifyPersonInfo } from '@/api/user';
import { Popup, Toast, RadioGroup, Radio } from 'vant';

export default {
  name: 'PersonalInfo',
  components: {
    ImgDecypt,
    [Popup.name]: Popup,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
  },
  computed: {
    gender() {
      if (this.userInfo.gender == '') {
        return '';
      }
      if (this.userInfo.gender == 'man') {
        return '男';
      }
      if (this.userInfo.gender == 'woman') {
        return '女';
      }
      return '';
    },
  },
  data() {
    return {
      userSex: '',
      isChangeSex: false,
      userInfo: {},
      currentSex: undefined,
      activeIcon: require('@/assets/png/activeIcon.png'),
      inactiveIcon: require('@/assets/png/inactiveIcon.png'),
    };
  },
  created() {
    this.getUserInfo();
  },
  methods: {
    // 获取用户信息
    async getUserInfo() {
      await this.$store.dispatch('user/getUserInfo');
      this.userInfo = this.$store.getters.userInfo;
      this.currentSex = this.userInfo.gender;
    },
    // 跳转修改昵称
    jumpNickName() {
      if (this.userInfo.isVip && this.userInfo.vipLevel > 0) {
        this.$router.push({
          path: '/nickName',
          query: { name: this.userInfo.name },
        });
      } else {
        // Toast('抱歉，只有会员才可以修改资料')
        this.$bus.$emit('promptPopupEvent', '您还不是VIP无法修改昵称');
      }
    },
    // 跳转修改简介
    jumpIntroduction() {
      // this.$router.push({path: '/introduction', query:{introduce: this.userInfo.introduce}})
      if (this.userInfo.isVip && this.userInfo.vipLevel > 0) {
        this.$router.push({
          path: '/introduction',
          query: { introduce: this.userInfo.introduce },
        });
      } else {
        // Toast('抱歉，只有会员才可以修改资料')
        this.$bus.$emit('promptPopupEvent', '您还不是VIP无法修改简介');
      }
    },
    // 修改所在地
    jumoCity() {
      this.$router.push({ path: '/city', query: { cityType: 'user' } });
      // if (this.checkVip()) {
      //     this.$router.push({path: '/city', query:{cityType: "user"}})
      // } else {
      //     Toast('抱歉，只有会员才可以修改资料')
      // }
    },
    // 修改性别
    changeSex() {
      this.isChangeSex = true;
      // if (this.checkVip()) {
      //     this.isChangeSex = true
      // } else {
      //     Toast('抱歉，只有会员才可以修改资料')
      // }
    },
    // 选择性别
    async selectSex() {
      let req = { gender: this.currentSex };
      try {
        let res = await this.$Api(modifyPersonInfo, req);
        this.isChangeSex = false;
        if (res && res.code == 200) {
          Toast('修改成功');
          this.getUserInfo();
        } else {
          Toast(res.tip || '修改失败，请稍后再试');
        }
      } catch (error) {
        this.isChangeSex = false;
        Toast('操作失败');
      }
    },
    // 校验是否会员、会员时间是否到期
    checkVip() {
      let todayTime = new Date().getTime();
      let effectiveTime = new Date(this.userInfo.vipExpireDate).getTime();
      if (this.userInfo && this.userInfo.isVip && todayTime < effectiveTime) {
        return true;
      }
      return false;
    },
    // 手机号跳转
    jumpPhone() {
      if (this.userInfo.mobile) {
        this.$router.push({
          path: '/phonePage',
          query: { phone: this.userInfo.mobile },
        });
      } else {
        this.$router.push('/bindPhone');
      }
    },
    changeAvatar() {
      this.$router.push('/changeAvatar');
      // if (this.checkVip()) {
      //     this.$router.push("/changeAvatar")
      // } else {
      //     Toast('抱歉，只有会员才可以切换头像')
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.personalInfo {
  height: 100%;
  background-color: #fff;

  .navBar {
    height: 44px;
    display: flex;
    padding: 0 16px;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    border-bottom: 1px solid rgb(217, 217, 217);
    .back {
      width: 80px;
      display: flex;
      align-items: center;

      .leftBtn {
        width: 24px;
        height: 24px;
        font-size: 24px;
      }

      span {
        font-size: 18px;
      }
    }

    .title {
      flex: 1;
      font-size: 18px;
      text-align: center;
      color: #000;
    }

    .rightBtn {
      width: 80px;
    }
  }

  .main {
    height: calc(100% - 44px);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding: 0 16px;
    .userAvatar {
      width: 80px;
      height: 80px;
      margin: 18px auto 0px;
    }

    .changeBtn {
      height: 28px;
      width: 100px;
      background-color: rgb(239, 239, 239);
      border-radius: 4px;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 8px auto;

      .changeBtnIcon {
        height: 18px;
        width: 18px;
      }
    }

    .cellType {
      font-size: 18px;
      margin: 30px 0 13px;
    }

    .cellItem {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 21px;

      .title {
        font-size: 16px;
        color: #8a8a8a;
      }

      .content {
        font-size: 17px;
        color: rgb(111, 93, 128);

        .pink {
          color: rgb(255, 103, 143);
        }
      }

      .personalArrow {
        margin-left: 3px;
        height: 20px;
        width: 20px;
      }
    }
  }

  .sexSelect {
    color: #000;
    text-align: center;
    font-size: 18px;
    border-radius: 12px 12px 0 0;

    .title {
      font-size: 20px;
      text-align: center;
      height: 48px;
      line-height: 48px;
      border-bottom: 1px solid rgb(229, 237, 245);
      margin: 0 12px;
    }

    .sexBox {
      margin: 28px auto;
      width: 170px;
      display: flex;
      font-size: 20px;
      justify-content: space-between;
      .maleBox {
        display: flex;
      }
      .femaleBox {
        display: flex;
      }
      .pink {
        color: rgb(255, 90, 144);
      }
      .img-icon {
        height: 20px;
        margin-left: 10px;
      }
    }

    .confirm {
      height: 38px;
      width: 190px;
      margin: 0 auto 36px;
      background-color: rgb(255, 103, 143);
      text-align: center;
      border-radius: 4px;
      color: #fff;
      line-height: 38px;
    }

    .sexItem {
      height: 65px;
      line-height: 65px;
      border-bottom: 1px solid #d9d9d9;
    }

    .sexItem:active {
      color: #fff;
      background: #fe7f0f;
    }

    .sexItem:last-child {
      border: none;
    }
  }

  .mt37 {
    margin-top: 37px;
  }

  .mb40 {
    margin-bottom: 40px;
  }
}
</style>
